<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-input v-model="filters.name" placeholder="客户名称，地址"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-on:click="getCustomers">
            <i class="el-icon-search"></i>
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAdd">
            <i class="el-icon-plus"></i>
          </el-button>
        </el-form-item>
        <el-form-item label="当前客户：">
          <customer-select :selected="customerid" @changeVal="toggleChange"></customer-select>
        </el-form-item>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table :data="customerslist" v-loading="listLoading" style="width: 100%;" stripe highlight-current-row>
      <el-table-column type="index" width="54">
      </el-table-column>
      <el-table-column prop="customername" label="客户名称" min-width="240" sortable>
      </el-table-column>
      <el-table-column prop="contact" label="联系方式" width="180">
      </el-table-column>
      <el-table-column prop="smsbalance" label="短信余额" min-width="120" sortable>
      </el-table-column>
      <el-table-column prop="area" label="公司地址" width="160" min-width="160" sortable>
      </el-table-column>
      <el-table-column prop="address" label="详细地址" min-width="500">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="150" min-width="150">
        <template slot-scope="scope">
          <el-button size="small" @click="handleEdit(scope.$index, scope.row)">
            <i class="el-icon-edit"></i>
          </el-button>
          <el-button type="warning" size="small" @click="handleDel(scope.$index, scope.row)">
            <i class="el-icon-delete"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="20" :total="total" style="float:right;">
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog title="编辑" :visible.sync="editFormVisible" :close-on-click-modal="false">
      <el-form :model="editForm" label-width="110px" :rules="editFormRules" ref="editForm">
        <el-form-item label="客户名称" prop="customername">
          <el-input v-model="editForm.customername" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="公司地址" prop="area">
          <el-cascader :options="cityOptions" v-model="editForm.area" placeholder="省/市/区">
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="editForm.address" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contact">
          <el-input v-model="editForm.contact" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="短信余额" prop="smsbalance">
          {{editForm.smsbalance}}条
        </el-form-item>
        <el-form-item label="新增短信条数" prop="smsadd">
          <el-input v-model.number="editForm.smsadd" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="editSubmit" :loading="editLoading">提交</el-button>
      </div>
    </el-dialog>

    <!--新增界面-->
    <el-dialog title="新增" :visible.sync="addFormVisible" :close-on-click-modal="false">
      <el-form :model="addForm" label-width="110px" :rules="addFormRules" ref="addForm">
        <el-form-item label="客户名称" prop="customername">
          <el-input v-model="addForm.customername" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="公司地址" prop="area">
          <el-cascader :options="cityOptions" v-model="addForm.area" placeholder="省/市/区">
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="addForm.address" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contact">
          <el-input v-model="addForm.contact" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="初始短信条数" prop="smsadd">
          <el-input v-model.number="addForm.smsadd" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import NProgress from "nprogress";
import { mapGetters, mapActions } from "vuex";
import CustomerSelect from "../components/CustomerSelect";
import { citys } from "../common/city.js";
export default {
  components: {
    CustomerSelect
  },
  data() {
    return {
      filters: {
        name: ""
      },
      customerslist: [],
      total: 0,
      page: 1,
      listLoading: false,
      cityOptions: citys,
      editFormVisible: false, //编辑界面是否显示
      editLoading: false,
      editFormRules: {
        customername: [
          {
            required: true,
            message: "请输入客户名称",
            trigger: "blur"
          },
          {
            min: 2,
            message: "请至少输入2个字符的客户名称",
            trigger: "blur"
          },
          {
            max: 20,
            message: "请输入少于20个字符的客户名称",
            trigger: "blur"
          }
        ],
        area: [
          {
            required: true,
            type: "array",
            min: 2,
            message: "请选择客户所在区域"
          }
        ],
        usertypeid: [
          {
            type: "number",
            required: true,
            message: "请选择用户类型",
            trigger: "blur"
          }
        ],
        customerid: [
          {
            type: "number",
            required: true,
            message: "请选择所属客户",
            trigger: "blur"
          }
        ],
        smsadd: [
          {
            type: "number",
            required: true,
            message: "请输入短信条数",
            trigger: "blur"
          }
        ]
      },
      //编辑界面数据
      editForm: {
        customerid: null,
        customername: "",
        area: [],
        address: "",
        contact: "",
        smsbalance: null,
        smsadd: 0
      },

      addFormVisible: false, //新增界面是否显示
      addLoading: false,
      addFormRules: {
        customername: [
          {
            required: true,
            message: "请输入客户名称",
            trigger: "blur"
          },
          {
            min: 2,
            message: "请至少输入2个字符的客户名称",
            trigger: "blur"
          },
          {
            max: 20,
            message: "请输入少于20个字符的客户名称",
            trigger: "blur"
          }
        ],
        area: [
          {
            required: true,
            type: "array",
            min: 2,
            message: "请选择客户所在区域"
          }
        ],
        usertypeid: [
          {
            type: "number",
            required: true,
            message: "请选择用户类型",
            trigger: "blur"
          }
        ],
        customerid: [
          {
            type: "number",
            required: true,
            message: "请选择所属客户",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "请输入初始密码",
            trigger: "blur"
          },
          {
            min: 6,
            message: "密码不得少于6位",
            trigger: "blur"
          },
          {
            max: 30,
            message: "最大支持30位",
            trigger: "blur"
          }
        ],
        smsadd: [
          {
            type: "number",
            required: true,
            message: "请输入初始短信条数",
            trigger: "blur"
          }
        ]
      },
      //新增界面数据
      addForm: {
        customername: "",
        area: [],
        address: "",
        contact: "",
        smsadd: 0
      }
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid"])
  },
  methods: {
    ...mapActions(["SetCustomerInfo"]),
    toggleChange(customerid, customername) {
      this.SetCustomerInfo({
        customerid,
        customername
      });
    },
    // 0:超管，1:保留，2:代理商超管，3:保留，4:客户管理员，5:客户普通用户
    formatUserType: (row, column) => {
      return row.usertypeid == 0
        ? "超级管理员"
        : row.usertypeid == 2
          ? "系统管理员"
          : row.usertypeid == 4 ? "管理员" : "普通用户";
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getCustomers();
    },
    //获取客户列表
    getCustomers() {
      this.listLoading = true;
      NProgress.start();
      this.axios
        .post("/user/getcustomers", {
          page: this.page,
          name: this.filters.name
        })
        .then(reply => {
          this.listLoading = false;
          NProgress.done();
          if (reply.data.code === 0) {
            this.total = reply.data.values.count;
            this.customerslist = reply.data.values.customers;
          } else {
            this.$message({
              message: `查询出错，错误码${reply.data.code},请联系管理员`,
              type: "warning"
            });
          }
        });
    },
    //删除
    handleDel: function(index, row) {
      this.$confirm("确认删除该客户吗?", "提示", {
        type: "warning"
      })
        .then(() => {
          this.listLoading = true;
          NProgress.start();
          this.axios
            .post("/user/deletecustomer", {
              customerid: row.customerid
            })
            .then(res => {
              this.listLoading = false;
              NProgress.done();
              if (res.data.code === 0) {
                this.$notify({
                  title: "成功",
                  message: "删除成功",
                  type: "success",
                  offset: 45
                });
                this.getCustomers();
              } else if (res.data.code === 102) {
                this.$message({
                  message: "您的权限不足",
                  type: "warning"
                });
              } else {
                this.$message({
                  message: `删除失败，错误码${res.data.code},请联系管理员`,
                  type: "warning"
                });
              }
            });
        })
        .catch(_ => {});
    },
    //显示编辑界面
    handleEdit: function(index, row) {
      this.editFormVisible = true;
      this.editForm = Object.assign(this.editForm, row);
    },
    //显示新增界面
    handleAdd: function() {
      this.addFormVisible = true;
      this.addForm = {
        customername: "",
        area: [],
        address: "",
        contact: "",
        smsadd: 0
      };
    },
    //编辑
    editSubmit: function() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            NProgress.start();
            let params = {};
            Object.assign(params, this.editForm);
            params.area = params.area.join(",");
            this.axios.post("/user/editcustomer", params).then(res => {
              this.editLoading = false;
              NProgress.done();
              if (res.data.code === 0) {
                this.$notify({
                  title: "成功",
                  message: "编辑成功",
                  type: "success",
                  offset: 45
                });
                this.$refs["editForm"].resetFields();
                this.editFormVisible = false;
                this.getCustomers();
              } else if (res.data.code === 101) {
                this.$message({
                  message: "您的短信余额不足，请先充值",
                  type: "warning"
                });
              } else if (res.data.code === 102) {
                this.$message({
                  message: "您的权限不足",
                  type: "warning"
                });
              } else if (res.data.code === 110) {
                this.$message({
                  message: "查询不到代理商短信信息",
                  type: "warning"
                });
              } else {
                this.$message({
                  message: `保存失败，错误码${res.data.code},请联系管理员`,
                  type: "warning"
                });
              }
            });
          });
        }
      });
    },
    //新增
    addSubmit: function() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.addLoading = true;
            NProgress.start();
            let params = {};
            Object.assign(params, this.addForm);
            params.available = 1
            params.area = params.area.join(",");
            this.axios.post("/user/addcustomer", params).then(res => {
              this.addLoading = false;
              NProgress.done();

              if (res.data.code === 0) {
                this.$refs["addForm"].resetFields();
                this.addFormVisible = false;
                this.getCustomers();
                this.$notify({
                  title: "成功",
                  message: "新增客户成功",
                  type: "success",
                  offset: 45
                });
              } else if (res.data.code === 102) {
                this.$message({
                  message: "您的权限不足，无法添加客户",
                  type: "warning"
                });
              } else if (res.data.code === 110) {
                this.$message({
                  message: "查询不到代理商短信信息",
                  type: "warning"
                });
              } else if (res.data.code === 101) {
                this.$refs["addForm"].resetFields();
                this.addFormVisible = false;
                this.getCustomers();
                this.$notify({
                  title: "成功",
                  message: "新增客户成功，但由于您的短信不足导致短信充值失败。",
                  type: "warning",
                  offset: 45
                });
              } else {
                this.$message({
                  message: `保存出错，错误码${res.data.code},请联系管理员`,
                  type: "warning"
                });
              }
            });
          });
        }
      });
    }
  },
  mounted() {
    this.getCustomers();
  }
};
</script>